$greenColor : #50d8af;
$blueColor : #0c2e8a;
$blackColor : #000000;
$whiteColor : #ffffff;
$grayColor : #e6e6e6;
$grayColorIcon : #9c9c9c;
$grayTextColor : #7e7e7e;
$grayBorderColor : #cfcfcf;

$bannerImageHeight: 60vh;
$primaryFontFamily : 'Open Sans',
sans-serif;
$secondaryFontFamily: "Montserrat", sans-serif;
$thirdFontFamily: "Raleway", sans-serif;